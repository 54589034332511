export const GET_DROPSHIPPING_REQUEST = 'GET_DROPSHIPPING_REQUES';
export const GET_DROPSHIPPING_SUCCESS = 'GET_DROPSHIPPING_SUCCESS';
export const GET_DROPSHIPPING_ERROR = 'GET_DROPSHIPPING_ERROR';

export const POST_UPLOAD_BUNDLE_INVOICE_REQUEST = 'POST_UPLOAD_BUNDLE_INVOICE_REQUEST';
export const POST_UPLOAD_BUNDLE_INVOICE_ERROR = 'POST_UPLOAD_BUNDLE_INVOICE_ERROR';
export const POST_UPLOAD_BUNDLE_INVOICE_SUCCESS = 'POST_UPLOAD_BUNDLE_INVOICE_SUCCESS';

export const PUT_BUNDLE_VALUE_REQUEST = 'PUT_BUNDLE_VALUE_REQUEST';
export const PUT_BUNDLE_VALUE_ERROR = 'PUT_BUNDLE_VALUE_ERROR';
export const PUT_BUNDLE_VALUE_SUCCESS = 'PUT_BUNDLE_VALUE_SUCCESS';

export const PATCH_ORDER_CHARGES_REQUEST = 'PATCH_ORDER_CHARGES_REQUEST';
export const PATCH_ORDER_CHARGES_SUCCESS = 'PATCH_ORDER_CHARGES_SUCCESS';
export const PATCH_ORDER_CHARGES_ERROR = 'PATCH_ORDER_CHARGES_ERROR';